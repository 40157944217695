<template>
    <section>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="page-header">
                            <h3 v-if="!editableSectionTitle" class="page-title">
                                {{sectionTitle}}
                                <span class="badge badge-outline-primary badge-pill pointer" @click="editableSectionTitle = !editableSectionTitle"><i class="fa fa-pencil"></i></span>
                            </h3>
                            <b-form-group v-else>
                                <b-input-group>
                                    <b-form-input placeholder="Username" v-model="sectionTitle"></b-form-input>
                                    <b-input-group-text slot="append" class="bg-primary text-white pointer" @click="editableSectionTitle = !editableSectionTitle">
                                    <span><i class="fa fa-save"></i></span>
                                    </b-input-group-text>
                                </b-input-group>
                            </b-form-group>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0);">Accueil</a></li>
                                <li class="breadcrumb-item active" aria-current="page">text</li>
                                </ol>
                            </nav>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-6">
                                <p>Ajoutez librement votre texte en choisissant le type de texte, leur taille et l'alignement</p>

                                <h4 class="text-muted mb-4">Ajouter un texte</h4>
                                    <b-form-group label="Type de texte"  label-for="text-element">
                                        <b-form-select type="text" id="text-element" :options="textElement" v-model="selectedTextElement" placeholder="Sélectionner le type de texte" >
                                        </b-form-select>
                                        <!-- <p>{{selectedTextElement}}</p> -->
                                    </b-form-group>

                                    <!-- Taille du titre -->
                                    <b-form-group v-show="selectedTextElement=='heading'" label="Taille du titre "  label-for="text-element">
                                        <b-form-select type="text" id="text-element" v-model="selectedTextElementSize" :options="textElementSize"  placeholder="Sélectionner le type de texte">
                                        </b-form-select>
                                        <!-- <p>{{selectedTextElementSize}}</p> -->
                                    </b-form-group>

                                    <!-- Alignement de texte -->
                                    <b-form-group label="Alignement du texte"  label-for="text-alignment">
                                        <b-form-select type="text" id="text-alignment" v-model="selectedTextElementAlignment" :options="textElementAlignment"  placeholder="Sélectionner le type de texte">
                                        </b-form-select>
                                        <!-- <p>{{selectedTextElementAlignment}}</p> -->
                                    </b-form-group>
                                    <!-- le texte en personne -->
                                    <b-form-group label="Texte"  label-for="text">
                                        <b-form-textarea type="text" id="text" placeholder="Texte" v-model="textDescription" :rows="3" :max-rows="6"></b-form-textarea>
                                    </b-form-group>
                                    <div class="d-flex justify-content-end">
                                        <b-button v-if="!showEditText" class="btn btn-gradient-primary" @click="addText()">Ajouter le texte</b-button>
                                        <span v-else>
                                            <button class="btn btn-link" @click="cancelEditingText()">Annuler</button>
                                            <b-button class="btn btn-gradient-info" @click="editText()">Modifier le texte</b-button>
                                        </span>
                                    </div>
                            </div> <!-- col-md-6 -->
                            
                            <div class="col-md-6">
                                <h4 class="text-muted mb-4">Ajouter un Bouton</h4>
                                    <b-form-checkbox
                                        id="button"
                                        v-model="button"
                                        name="button"
                                        value="button"
                                        unchecked-value="no_button"
                                        >
                                        Bouton
                                    </b-form-checkbox>
                                    <b-form-group v-show="button=='button'" class="mt-4" label="Titre du bouton">
                                        <b-form-input v-model="textButton" placeholder="Entrer le titre du bouton"></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-show="button=='button'" label="Lien du bouton">
                                        <b-form-input v-model="textButtonLink" placeholder="Entrer le lien du bouton"></b-form-input>
                                    </b-form-group>
                                    <b-form-group v-show="button=='button'" label="Alignement du bouton">
                                        <b-form-select type="text" id="text-element" v-model="selectedButtonAlignment" :options="buttonAlignment"  placeholder="Sélectionner le type de texte">
                                        </b-form-select>
                                        <!-- <p>{{selectedButtonAlignment}}</p> -->
                                    </b-form-group>
                            </div> <!-- col-md-6-->

                            <div class="col-md-12">
                                <hr>
                                <!-- type de texte -->
                                <h4 class="text-muted mb-4">Représentation du texte</h4>
                                <span class="d-flex justify-content-between" v-for="(description, descriptionId) in displayText" :key="descriptionId">
                                    <heading v-if="description.element=='heading'" :text="description.data['heading-text']" :justify="description.data['heading-justify']" class="pointer"/>
                                    <paragraph v-else-if="description.element=='paragraph'" :text="description.data['paragraph-text']" :justify="description.data['paragraph-justify']"></paragraph>
                                    <span>
                                        <i class="fa fa-pencil pointer mx-2" @click="selectText(description)"></i>
                                        <i class="fa fa-trash pointer" @click="deleteText(description)"></i>
                                    </span>
                                </span>
                            </div> <!--col-md-12 -->
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                        <button class="btn btn-link" @click="previousPage()">Annuler</button>
                        <button class="btn btn-gradient-primary" @click="saveText()">Enregistrer</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import paragraph from '../../../components/element/text/paragraph.vue'
import Heading from '../../../components/element/text/heading.vue'
export default {
    name: 'add-section-text',
    components : {
        paragraph,
        Heading
    },
    data () {
        return {
            ulid: '',
            editableSectionTitle: false,
            sectionTitle: '',
            section: '' ,
            selectedTextElement: '',
            textElement: [  
                { value: 'heading', text:'En-tête'},
                { value: 'paragraph', text:'paragraphe'}
            ],
            textElementSize: [
                { value: 'h1', text: 'En-tête 1'},
                { value: 'h2', text: 'En-tête 2'},
                { value: 'h3', text: 'En-tête 3'},
                { value: 'h4', text: 'En-tête 4'},
                { value: 'h5', text: 'En-tête 5'},
                { value: 'h6', text: 'En-tête 6'}
            ],
            textElementAlignment: [
                { value: 'left', text: 'Gauche'},
                { value: 'right', text: 'Droite'},
                { value: 'center', text: 'Centre'}
            ],
            selectedTextElementSize: '',
            selectedTextElementAlignment: '',
            textDescription:'',
            displayText: [],
            button: 'no_button',
            textButton: '',
            textButtonLink:'',
            buttonAlignment: [
                { value: 'justify-content-start', text: 'Gauche'},
                { value: 'justify-content-end', text: 'Droite'},
                { value: 'justify-content-center', text: 'Centre'}
            ],
            selectedButtonAlignment: '',
            indexOfDescription: 0,
            showEditText: false
        }
    },
    methods: {
        editSectionTitle () {
            this.editableSectionTitle = !this.editableSectionTitle
            console.log(this.editableSectionTitle);
        },
        getSectionInfo (ulid) {
            axios.get(`sections/${ulid}`)
            .then (resSectionInfo => {
                console.log({resSectionInfo})
                this.section = resSectionInfo.data
                this.sectionTitle = resSectionInfo.data.title
                //displayText = affichage des data -> text []
                this.displayText = resSectionInfo.data.blocks[0].data.text
                console.log('section in getSectionInfo', this.section)
                })
            .catch (errSectionInfo => console.log({errSectionInfo}))
        },
        addText () {
            let text = this.displayText
            if ( this.selectedTextElement =='paragraph') {
                text.push(
                    {
                        "order": `${text.length + 1}`,
                        "name" :"Message de bienvenue",
                        "element" : `${this.selectedTextElement}`,
                        "data": {
                            "paragraph-text": `${this.textDescription}`,
                            "paragraph-justify": `${this.selectedTextElementAlignment}`
                            }
                    }
                )
            } else if ( this.selectedTextElement =='heading') {
                text.push(
                    {
                        "order": 1,
                        "name" : "titre",
                        "element": `${this.selectedTextElement}`,
                        "data": { 
                            "heading-text": `${this.textDescription}`,
                            "heading-justify":`${this.selectedTextElementAlignment}`,
                            "heading-type": `${this.selectedTextElementSize}`
                        }
                    }
                )
            } else {
                alert ('Sélectionner un type de texte')
            }
            this.displayText= text
            console.log('text pushé', text);
            console.log('carouselDescrit pushé', this.displayText);
            this.selectedTextElement=''
            this.selectedTextElementSize=''
            this.selectedTextElementAlignment=''
            this.textDescription=''
        },
        editText () {
            this.displayText.splice(this.indexOfDescription, 1)
            this.addText()
        },
        deleteText (description) {
            const descriptionIndex = this.displayText.indexOf(description)
            this.displayText.splice(descriptionIndex,1)
        },
        selectText(description) {
            this.showEditText = true
            console.log({description})
            this.indexOfDescription = this.displayText.indexOf(description)
            console.log(this.displayText.indexOf(description));
            if (description.element == 'heading') {
                this.selectedTextElement = description.element
                this.selectedTextElementAlignment = description.data['heading-justify']
                this.selectedTextElementSize = description.data['heading-type']
                this.textDescription = description.data['heading-text']
            } else if ( description.element == 'paragraph') {
                this.selectedTextElement = description.element
                this.selectedTextElementAlignment = description.data['paragraph-justify']
                this.textDescription = description.data['paragraph-text']
            } else {
                console.log('il n\' y a pas de texte à modifier')
            }
        },
        cancelEditingText () {
            console.log('cancel');
            this.selectedTextElement = ''
            this.selectedTextElementAlignment = ''
            this.textDescription= ''
            this.showEditText = false
        },
        saveText () {
            axios.put(`sections/${this.ulid}`,{
                'title': this.sectionTitle,
                'blocks': [
                    {
                        'element':  "text",
                        'data': {
                            'text' : this.displayText
                        }
                    }
                ]
            },{
                headers:{
                    'X-AUTH-TOKEN': localStorage.getItem('token')
                }
            })
            .then(resSaveText => {
                this.$snotify.success('Texte enregistré avec succès', {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                })
                console.log({resSaveText})
                })
            .catch(errSaveText => console.log({errSaveText}))
        },
        previousPage(){
            this.$router.go(-1)
        }
    },
    mounted () {
        this.ulid = this.$route.params.ulid
        this.getSectionInfo(this.ulid)
    }
}
</script>